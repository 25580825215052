import { useMemo } from 'react';
import {
  usePlacesWidget,
  ReactGoogleAutocompleteProps,
} from 'react-google-autocomplete';
import getConfig from 'next/config';

import { LANGUAGE_CODE } from '@/utils/constants';

const {
  publicRuntimeConfig: { GOOGLE_MAPS_API_KEY },
} = getConfig();

export type AddressAutoCompleteTarget = 'city' | 'street';

export type AddressAutoCompleteBounds =
  google.maps.places.AutocompleteOptions['bounds'];

const commonPlacesWidgetProps: Partial<ReactGoogleAutocompleteProps> = {
  apiKey: GOOGLE_MAPS_API_KEY,
  language: LANGUAGE_CODE,
  options: {
    fields: ['place_id', 'formatted_address', 'geometry'],
    componentRestrictions: { country: 'ua' },
  },
};

export interface IAddressAutoCompleteValue {
  placeId: string;
  coordinates: ICoordinates;
  formattedAddress: string;
}

export interface IAddressAutoCompleteParams {
  type: AddressAutoCompleteTarget;
  bounds?: AddressAutoCompleteBounds;
  onSelectPlace: (
    value: IAddressAutoCompleteValue,
    bounds?: AddressAutoCompleteBounds,
  ) => void;
  onSelectNotExistedPlace: () => void;
}

const getPlacesWidgetProps = (
  autoCompleteTarget: AddressAutoCompleteTarget,
  bounds?: AddressAutoCompleteBounds,
): Partial<ReactGoogleAutocompleteProps> => {
  switch (autoCompleteTarget) {
    case 'city':
      return {
        ...commonPlacesWidgetProps,
        inputAutocompleteValue: 'address-level2',
        options: {
          ...commonPlacesWidgetProps.options,
          types: ['(cities)'],
          bounds,
        },
      };
    case 'street':
      return {
        ...commonPlacesWidgetProps,
        inputAutocompleteValue: 'street-address',
        options: {
          ...commonPlacesWidgetProps.options,
          types: ['address'],
          strictBounds: Boolean(bounds),
          bounds,
        },
      };
    default:
      return commonPlacesWidgetProps;
  }
};

export const useAddressAutoComplete = ({
  type,
  bounds,
  onSelectPlace,
  onSelectNotExistedPlace,
}: IAddressAutoCompleteParams) => {
  const placesWidgetProps = useMemo(
    () => getPlacesWidgetProps(type, bounds),
    [bounds, type],
  );

  const { ref } = usePlacesWidget<HTMLInputElement | null>({
    ...placesWidgetProps,
    onPlaceSelected: (place: google.maps.places.PlaceResult) => {
      if (
        place.place_id &&
        place.formatted_address &&
        place.geometry?.location
      ) {
        onSelectPlace(
          {
            placeId: place.place_id,
            formattedAddress: place.formatted_address,
            coordinates: place.geometry.location.toJSON(),
          },
          place.geometry.viewport,
        );
      } else {
        onSelectNotExistedPlace();
      }
    },
  });

  return ref as React.MutableRefObject<HTMLInputElement | null>;
};

export const AUTO_COMPLETE_CLASS_NAME = '.pac-container';
