import { useCallback } from 'react';
import { useRouter } from 'next/router';

import type { AppRoute } from '@/route/path';

import { RouterQueryConfig, useLinkBuilder } from './use-link';

export const useNavigate = <R extends AppRoute>(route: R) => {
  const { push } = useRouter();
  const linkBuilder = useLinkBuilder();

  return useCallback(
    (config?: RouterQueryConfig<R>) =>
      push(linkBuilder(route, config), undefined, { shallow: true }),
    [push, route, linkBuilder],
  );
};

export const useSameRouteNavigate = () => {
  const { route } = useRouter();
  return useNavigate(route as AppRoute);
};
