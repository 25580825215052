import React, {
  forwardRef,
  useRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import GlobalStyles from '@mui/material/GlobalStyles';

import {
  AUTO_COMPLETE_CLASS_NAME,
  AddressAutoCompleteBounds,
  IAddressAutoCompleteParams,
  IAddressAutoCompleteValue,
  useAddressAutoComplete,
} from '@/hooks/use-address-auto-complete';

const incorrectAddressSelectedHelpText = 'Будь-ласка оберіть адресу зі списку';

const getAddressFieldId = (type: string) => `${type}-address-input-field`;

const addressInputGlobalStyles = (
  <GlobalStyles styles={{ [AUTO_COMPLETE_CLASS_NAME]: { zIndex: 10000 } }} />
);

export type AddressValue = IAddressAutoCompleteValue;
export type AddressBounds = AddressAutoCompleteBounds;

export interface IMuiAddressInputFieldProps
  extends Omit<
    IAddressAutoCompleteParams & Omit<TextFieldProps, 'onChange' | 'ref'>,
    'onSelectPlace' | 'onSelectNotExistedPlace'
  > {
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  onClear: () => void;
  onChange: IAddressAutoCompleteParams['onSelectPlace'];
}

export interface IMuiAddressInputFieldImperativeHandle {
  clearInputValue: () => void;
  setInputValue: (newValue: string) => void;
}

export const MuiAddressInputField = forwardRef<
  IMuiAddressInputFieldImperativeHandle,
  IMuiAddressInputFieldProps
>(({ type, bounds, error, onChange, onClear, ...props }, forwardedRef) => {
  const [notExistedPlaceSelected, setNotExistedPlaceSelected] = useState(false);
  const helperText = notExistedPlaceSelected
    ? incorrectAddressSelectedHelpText
    : props.helperText;

  const onSelectPlace = useCallback<
    IAddressAutoCompleteParams['onSelectPlace']
  >(
    (...args) => {
      setNotExistedPlaceSelected(false);
      onChange(...args);
    },
    [onChange],
  );

  const onSelectNotExistedPlace = useCallback(() => {
    setNotExistedPlaceSelected(true);
    onClear();
  }, [onClear]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const widgetInputRef = useAddressAutoComplete({
    type,
    bounds,
    onSelectPlace,
    onSelectNotExistedPlace,
  });

  const setInputRef = useCallback(
    (input: HTMLInputElement | null) => {
      inputRef.current = input;
      widgetInputRef.current = input;
    },
    [widgetInputRef],
  );

  useImperativeHandle(
    forwardedRef,
    () => ({
      clearInputValue: () => {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      },
      setInputValue: (value: string) => {
        if (inputRef.current) {
          inputRef.current.value = value;
        }
      },
    }),
    [],
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNotExistedPlaceSelected(false);
      if (!e.target.value) {
        onClear();
      }
    },
    [onClear],
  );

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    },
    [],
  );

  return (
    <>
      <TextField
        {...props}
        id={getAddressFieldId(type)}
        inputRef={setInputRef}
        onChange={onInputChange}
        onKeyDown={onInputKeyDown}
        error={notExistedPlaceSelected || error}
        helperText={helperText}
        placeholder={props.label?.toString()}
        color="primary"
        variant="outlined"
        fullWidth
      />
      {addressInputGlobalStyles}
    </>
  );
});

MuiAddressInputField.displayName = 'MuiAddressInputField';
