import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const StyledForm = styled('form')(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  ['& > *:not(:last-child)']: {
    marginBottom: theme.spacing(3),
  },
}));

export interface ITitledFormLayoutProps {
  onSubmit?: React.FormEventHandler;
  title?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  submitButton: React.ReactNode;
  children: React.ReactNode;
}

export function TitledFormLayout({
  onSubmit,
  title,
  submitButton,
  header,
  footer,
  children,
}: ITitledFormLayoutProps) {
  return (
    <StyledForm onSubmit={onSubmit}>
      {header}
      <Typography variant="h5">{title}</Typography>
      {children}
      {submitButton}
      {footer}
    </StyledForm>
  );
}
