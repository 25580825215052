import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import { AppRoute, buildRoutePath } from '@/route/path';
import type { AppRouterQueryParamKeys } from '@/route/query';
import { useAppSelector } from '@/store/app';
import { selectQueryParamsState } from '@/store/services';

type ArrayValue<T> = T extends (infer V)[] ? V : never;

export type RouterQueryConfig<R extends AppRoute> = {
  pick?: AppRouterQueryParamKeys[R];
  omit?: AppRouterQueryParamKeys[R];
  merge?: Partial<
    Record<
      ArrayValue<AppRouterQueryParamKeys[R]>,
      string | number | (string | number)[]
    >
  >;
};

export const SAME_ROUTE = '${same-route}';

export const useLinkBuilder = () => {
  const query = useAppSelector(selectQueryParamsState);

  return useCallback(
    <R extends AppRoute>(
      route: R,
      { pick, omit, merge }: RouterQueryConfig<R> = {},
    ) => {
      let routeQuery = _.clone(query);

      if (pick) routeQuery = _.pick(routeQuery, pick);
      if (omit) routeQuery = _.omit(routeQuery, omit);
      if (merge) routeQuery = _.merge(routeQuery, merge);

      return buildRoutePath(route, routeQuery);
    },
    [query],
  );
};

export const useLink = <R extends AppRoute>(
  route: R,
  { pick, omit, merge }: RouterQueryConfig<R> = {},
) => {
  const linkBuilder = useLinkBuilder();

  return useMemo(
    () => linkBuilder(route, { pick, omit, merge }),
    [route, linkBuilder, pick, omit, merge],
  );
};

export const useSameRouteLink = ({
  pick,
  omit,
  merge,
}: RouterQueryConfig<AppRoute> = {}) => {
  const { route } = useRouter();
  const linkBuilder = useLinkBuilder();

  return useMemo(
    () => linkBuilder(route as AppRoute, { pick, omit, merge }),
    [route, linkBuilder, pick, omit, merge],
  );
};
