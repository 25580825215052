import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import { useAppHeight } from '@/contexts/app-height';

export type IFullScreenBoxProps = BoxProps;

export function FullScreenBox(props: IFullScreenBoxProps) {
  const height = useAppHeight();
  return (
    <StyledBox
      {...props}
      sx={{ ...props.sx, height, minHeight: height, maxHeight: height }}
    />
  );
}

const StyledBox = styled(Box)<IFullScreenBoxProps>({
  width: '100vw',
  height: '100vh',
  minHeight: '100vh',
  maxHeight: '100vh',
  position: 'relative',
});
