import React from 'react';
import { useTheme } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import type { SvgIconTypeMap } from '@mui/material';

export interface IBackButtonProps extends IconButtonProps {
  iconProps?: SvgIconTypeMap['props'];
}

export function BackButton({ iconProps, ...props }: IBackButtonProps) {
  const theme = useTheme();
  return (
    <IconButton
      color="secondary"
      {...props}
      sx={{
        backgroundColor: theme.palette.background.paper,
        ...props.sx,
      }}
    >
      <ArrowBackIcon fontSize="small" {...iconProps} />
    </IconButton>
  );
}

export function SecondaryBackButton({ iconProps, ...props }: IBackButtonProps) {
  return (
    <IconButton
      size="small"
      {...props}
      sx={{
        color: 'background.paper',
        borderRadius: 1,
        backgroundColor: 'secondary.main',
        ['&:hover']: {
          backgroundColor: 'secondary.main',
        },
        ...props.sx,
      }}
    >
      <ArrowBackIcon fontSize="medium" {...iconProps} />
    </IconButton>
  );
}
