import { ParsedUrlQuery, stringify } from 'querystring';
import { pick } from 'lodash';

import type { AppRouterQueryParamKeys } from './query';

export const enum AppRoute {
  signIn = '/sign-in',
  shops = '/shops',
  products = '/products',
}

const AppRouteQueryParams: AppRouterQueryParamKeys = {
  [AppRoute.signIn]: [],
  [AppRoute.shops]: ['shopId', 'shopTab', 'productId', 'productTab'],
  [AppRoute.products]: [
    'lat',
    'lng',
    'categoryId',
    'productId',
    'productTab',
    'shopId',
    'shopTab',
  ],
};

export const buildRoutePath = (
  route: AppRoute,
  query: IAppQueryParams | ParsedUrlQuery = {},
) => {
  const routeQuery = pick(query, AppRouteQueryParams[route]);
  const params = stringify(routeQuery);
  return route + (params ? '?' + params : '');
};

export const AppRoutePath = {
  signIn: () => AppRoute.signIn,
  shops: (query?: IAppQueryParams | ParsedUrlQuery) =>
    buildRoutePath(AppRoute.shops, query),
  products: (query?: IAppQueryParams | ParsedUrlQuery) =>
    buildRoutePath(AppRoute.products, query),
};
