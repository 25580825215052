import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

export interface ILoaderProps extends BoxProps {
  color?: CircularProgressProps['color'];
  size?: CircularProgressProps['size'];
}

export function Loader({ size, color, ...props }: ILoaderProps) {
  return (
    <Box
      {...props}
      sx={{
        width: '100%',
        height: '100%',
        flexGrow: 1,
        ...props.sx,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={size} color={color} />
    </Box>
  );
}
